import React from "react";
import Head from "../../components/head.js";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Nobo = () => {
  return (
    <Layout>
      <Head title="Nobuyuki Satow" />
      <h3>Nobuyuki Satow</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Kitakyushu, Japan
      </h4>
      <p>
        <OutboundLink href="https://www.instagram.com/tsunzaku/?hl=en">
          Instagram
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.behance.net/tsunzaku">
          Behance
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://tsun-zaku.bandcamp.com/">
          Bandcamp
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://ello.co/tsun-zaku">Ello</OutboundLink>
      </p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15861446/ello-optimized-300daf87.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15861450/ello-optimized-c083e4c4.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15424108/ello-optimized-ed979422.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/13571725/ello-optimized-81c44c62.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/13496247/ello-optimized-11eb89d7.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/9145904/ello-optimized-665120d6.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/9145905/ello-optimized-fc54d817.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/9145906/ello-optimized-adf92bac.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/14980352/ello-optimized-87df0eb5.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/14980362/ello-optimized-d6adbd2c.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14980380/ello-optimized-f5520c32.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/14980392/ello-optimized-368a7618.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/9562646/ello-optimized-cac2f2b9.jpg"
        alt="Nobuyuki Satow art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/7187665/ello-optimized-021ce1ed.jpg"
        alt="Nobuyuki Satow art"
      />
    </Layout>
  );
};

export default Nobo;
